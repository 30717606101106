import { createTheme } from '@mui/material/styles';
import { red, common } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: "#333",
          backgroundColor: "#f5f8f8",
          lineHeight: "20px",
          minHeight: "100%",
          fontSize: "14px",
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          fontSize: "1.38889vw",
          lineHeight: "120%",
          '@media screen and (max-width: 479px)': {
            fontSize: '5.33333vw',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "1.38889vw",
          lineHeight: "120%",
          '@media screen and (max-width: 479px)': {
            fontSize: '5.33333vw',
          },
        },

      },
    },
  },
  palette: {
    primary: {
      main: '#284E51',
    },
    secondary: {
      main: '#295053',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#FBFDFD',
      paper: '#E9EFEF',
    },
    text: {
      primary: '#284E51',
      secondary: common.black,
    },
  },
  typography: {
    fontFamily: `"Gilroy", "Arial", sans-serif`,
    fontSize: 14,
  },
});

export default theme;
