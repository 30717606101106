import LogoImages from '../../../components/LogoImages';

import { Urls } from '../../../constants/urls.constants';
import i18n from '../../../i18n/I18nManager';

export default function TrackerSection({children}) {
  return (
    <section className="tracker-section">
      <div className="w-layout-blockcontainer container-default w-container">
        <div className="content-block">
          <div className="tracker-wrapper">
            <div>
              <div className="tracker-logo-wrapper">
                <LogoImages isDarkBackground />
              </div>
              <div className="tracker-search-wrapper">
                <div className="has--no-margin w-form">
                  {children}
                </div>
              </div>
              <div className="tracker-search-subtitle">
                <p className="paragraph-small is--color-transparent-white">
                  {i18n.getText('HaveTroubles') + " "}
                  <a href={Urls.GetHelp} className="search-span-link">{i18n.getText('GetHelp')}</a>
                </p>
              </div>
            </div>
            <div id="w-node-_8af572fc-ff42-d31e-9a6f-537aa75d9d83-210695fd" className="tracker-hero_image-wrapper">
              <img src="images/man_1man.webp" loading="lazy" sizes="(max-width: 479px) 100vw, 48vw" srcSet="images/man_1-p-500.png 500w, images/man_1-p-800.png 800w, images/man_1-p-1080.png 1080w, images/man_1-p-1600.png 1600w, images/man_1-p-2000.png 2000w, images/man_1man.webp 2472w" alt="" className="tracker-hero-image" /></div>
          </div>
        </div>
      </div>
    </section>
  );
}