import * as React from 'react';

import { StepIconProps } from '@mui/material/StepIcon';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { ShipmentStep } from '../ShipmentStatus';

import i18n from '../../../i18n/I18nManager';

function StepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  if (completed) {
    return (
      <div className="tracker-field_image-wrapper">
        <img src="images/Vector.svg" loading="lazy" alt="" className="tracker-field-image" />
      </div>
    );
  }
  if (active) {
    return (
      <div className="tracker-field_image-wrapper is--now">
        <img src="images/ant-design_arrow-up-outlined.svg" loading="lazy" alt="" className="tracker-field-image is--now" />
      </div>
    );
  }
  return (
    <div className="tracker-field_image-wrapper is--future"></div>
  );
}

export default function StatusStepper({ steps, showExtraHistory }: { steps: ShipmentStep[], showExtraHistory?: boolean }) {
  return (
    <Stepper orientation="vertical" connector={<Box className="tracker-field_image-divider" ></Box>}>
      {steps.map((step) => 
       (showExtraHistory  || !step.ExtraHistory ) &&
        <Step
          completed={step.IsCompleted}
          active={step.IsCurrent}
          key={step.Key}>
          <StepLabel
            sx={{ padding: 0 }}
            StepIconComponent={StepIcon}>
            <p className={`paragraph-small ${step.IsCompleted && "is--past"} ${step.IsCurrent && "is--now"} ${step.IsFuture && "is--future"}`}>
              {step.Description}
              {step.Date && <><br />{i18n.formatDateTime(step.Date)}</>}
            </p>
          </StepLabel>
        </Step>
      )}
    </Stepper>
  );
}