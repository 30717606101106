import * as React from 'react';

export default function ErrorMessage({ value }: { value: any }) {
    if (value) {
        return (
            <div className="w-form-fail" style={{ display: "block" }}>
                <div>{value}</div>
            </div>
        )
    }

    return <></>;
}