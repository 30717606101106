import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import StatusStepper from './StatusStepper';
import { ShipmentStepResult } from '../ShipmentStatus';

import i18n from '../../../i18n/I18nManager';

export default function DetailsModal({ details, externalLink }: { details: ShipmentStepResult | null, externalLink: string }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <a href="" onClick={handleClickOpen} className="tracker-field_shiping-link">{i18n.getText('ShipmentStatus.ViewDetails')}</a>
      <Dialog
        PaperProps={{
          style: {
            zIndex: 2,
            borderRadius: "1.73611vw",
            padding: "3.472vw",
          },
        }}
        scroll='body'
        open={open}
        onClose={handleClose}
      >
        <Box className="popup-cross"
          aria-label="close"
          onClick={handleClose}
          sx={{
            backgroundColor: "#284E51"
          }} >
          <img src="images/x-circle.svg" loading="lazy" alt="" className="popup-cross-image" />
        </Box>
        <div className="w-form">
          <div className="popup-heading popup-heading-linked">
            <h4 className="popup-heading-linked-text">{i18n.getText('ShipmentStatus.DetailsTitle')}</h4>
            <Tooltip title={i18n.getText('ShipmentStatus.ExternalLink')}>
              <a href={externalLink}><OpenInNewIcon /></a>
            </Tooltip>
          </div>
          <div className="popup-subtitle">
            {details?.Steps &&
              <StatusStepper steps={details.Steps} showExtraHistory />
            }
          </div>
        </div>
      </Dialog>
    </>
  );
}