//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ShipmentStatus {
	Error = 0,
	Created = 1,
	Cancelled = 2,
	AcceptedInSendingWarehouse = 3,
	ReadyToShip = 4,
	SendedFromUkraineWarehouse = 5,
	ShippedToReceivingWarehouse = 6,
	AcceptedInReceivingWarehouse = 7,
	ShippedToCustomer = 8,
	PartiallyShipped = 9,
	HasProblem = 10,
	Lost = 11,
	ReturnsWarehouse = 12,
	ShippedFromReturnsWarehouse = 13,
	Disposed = 14,
	AcceptedAtFulfillmentWarehouse = 15,
	OnTheWay = 16
}
