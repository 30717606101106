import * as React from 'react';
import { useLocation, useSearchParams } from "react-router-dom";

import Box from '@mui/material/Box';

import LoadableContent from '../../components/LoadableContent';
import ErrorMessage from '../../components/ErrorMessage';
import WestParcelSection from './sections/WestParcelSection';
import TrackerSection from './sections/TrackerSection';
import TrackerFieldSection from './sections/TrackerFieldSection';
import ShipmentStatusResult from './components/ShipmentStatusResult';

import { GetCurrentStatusRequest } from './ShipmentStatus';
import useServiceApi from "../../hooks/useServiceApi";
import i18n from '../../i18n/I18nManager';
import { config } from '../../config';
import { IShipmentStatusModel } from '../../api/TrackingApi/IShipmentStatusModel';

const trackNumberRegExp = /^[A-Za-z0-9\.\-]{3,50}$/;

export default function TrackPage() {
    const location = useLocation();
    const [{ data, isLoading, isError }, loadData, clearResult] = useServiceApi();
    const [validationError, setValidationError] = React.useState("");
    const [searchParams, setSearchParams] = useSearchParams();

    const [trackNumber, setTrackNumber] = React.useState(searchParams.get("trackNumber") || "");
    const [isSubmited, setIsSubmited] = React.useState(false);

    React.useEffect(() => {
        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1
        );
        const element = document.getElementById(href);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    React.useEffect(() => {
        if (!location.search) {
            clearResult();
        }
    }, [location.search]);


    const handleSubmit = (event: React.FormEvent<HTMLFormElement> | null) => {
        event?.preventDefault();
        if (isLoading) {
            return;
        }

        setIsSubmited(true);
        if (trackNumberRegExp.test(trackNumber)) {
            setValidationError("");
            grecaptcha.enterprise.ready(async () => {
                const token = await grecaptcha.enterprise.execute(config.googleRecaptchaKeyId, { action: 'TRACK' });
                if (token) {
                    loadData(GetCurrentStatusRequest(trackNumber, token))?.then(() => {
                        let currentUrlParams = new URLSearchParams(window.location.search);
                        currentUrlParams.set('trackNumber', trackNumber);
                        setSearchParams(currentUrlParams);
                    });
                }
            });
        }
        else {
            setValidationError(i18n.getText('trackNumberInputError'));
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        value = value.replace(/\s/g, '');
        setTrackNumber(value);

        if (isSubmited) {
            if (!trackNumberRegExp.test(value)) {
                setValidationError(i18n.getText('trackNumberInputError'));
            }
            else {
                clearResult();
                setValidationError("");
            }
        }
    }

    let errorMessage = validationError || (isError && (data && data.Message ? data.Message : "Error!"))
        || (Array.isArray(data) && data.length == 0 && i18n.getText('trackNumberNotFound'));

    if (!errorMessage && data) {
        return (
            <TrackerFieldSection>
                {(data as IShipmentStatusModel[]).map((column, key) => <ShipmentStatusResult key={key} status={column} />)}
            </TrackerFieldSection>
        )
    }

    return (
        <>
            <LoadableContent isLoading={isLoading} />
            <TrackerSection>
                <Box component="form" className="tracker-form" onSubmit={handleSubmit} noValidate >
                    <div className="tracker-input-wrapper">
                        <img src="images/search.svg" loading="lazy" alt="" className="search-icon" />
                        <input type="text"
                            className="tracker-input w-input"
                            maxLength={50}
                            name="trackNumber"
                            data-name="trackNumber"
                            placeholder={i18n.getText('TrackingNumber')}
                            id="trackNumber"
                            required={true}
                            value={trackNumber}
                            onChange={handleChange}
                            autoFocus
                        />
                    </div>
                    <input
                        value={i18n.getText('track.button')}
                        className="button is--white has--margin_lr-66 w-button is--form"
                        type="submit"
                        style={{ zIndex: "2" }}
                    />
                </Box>
                <ErrorMessage value={errorMessage} />
            </TrackerSection>
            <WestParcelSection />
        </>
    );
}
