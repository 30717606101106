import { useState } from "react";

const useServiceApi = () => {
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const loadData = (request: TApiRequest) => {
    if (!isLoading) {
      const fetchData = async () => {
        setIsLoading(true);
        clearResult();
        try {
          const response = await fetch(request.url, request.init);
          let isError = false;
          if (!response.ok) {
            if (response.status == 401) { //Unauthorized
              window.location.href = '/';
            }
            else {
              isError = true;
            }
          }

          let responseData: any;
          let contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            responseData = await response.json();
          } else if (contentType && contentType.includes("text/plain")) {
            responseData = await response.text();
          } else {
            responseData = { isLoaded: true };
          }

          setData(responseData);
          setIsError(isError);
        } catch (error) {
          setIsError(true);
        }
        setIsLoading(false);
      };

      if (request.url) {
        return fetchData();
      }
    }
  };

  const clearResult = () => {
    if (!isLoading) {
        setIsError(false);
        setData(null);
    }
  };

  return [{ data, isLoading, isError }, loadData, clearResult] as const;
};

export default useServiceApi;

export type TApiRequest = {
  url: RequestInfo;
  init?: RequestInit;
};