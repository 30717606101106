import * as React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";

import Track from './pages/Track/Track';
import FooterSection from './components/FooterSection';
import i18n from './i18n/I18nManager';

function Copyright() {
  return (
    <div className="alternative-footer-subtitle">
      <div className="footer-text is--color-green">
        {`© ${new Date().getFullYear()} ${i18n.getText('app.name')}, Inc. All Rights Reserved`}
      </div>
    </div>
  );
}

function PageWrapper() {
  return (
    <div className="page-wrapper">
      <Track />
      <FooterSection />
      <Copyright />
    </div>
  );
}

let router = createBrowserRouter([
  {
    path: "/",
    Component: PageWrapper
  },
  {
    path: "*",
    Component() { return <Navigate to='/' replace />; },
  },
]);
export default function App() {
  return (
    <RouterProvider router={router} />
  );
}
