import * as React from 'react';
import { Link } from "react-router-dom";

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';

import ErrorMessage from '../../../components/ErrorMessage';
import ExternalLinkNotice from './ExternalLinkNotice';
import DetailsModal from './DetailsModal';
import StatusStepper from './StatusStepper';

import { IShipmentStatusModel } from '../../../api/TrackingApi/IShipmentStatusModel';
import { ShipmentStepResult, GetShipmentSteps } from '../ShipmentStatus';
import i18n from '../../../i18n/I18nManager';

export default function ShipmentStatusResult({ status }: { status: IShipmentStatusModel }) {
    const [steps, setSteps] = React.useState<ShipmentStepResult | null>(null);

    React.useEffect(() => {
        if (status) {
            setSteps(GetShipmentSteps(status));
        }
    }, [status]);

    return (
        <div className="tracker-field">
            <div className="tracker-field_shiping-number">
                <p className="paragraph-small is--color-transparent-green">{i18n.getText('shipmentStatus.TrackingNumber')}:</p>
                <Box sx={{ display: 'flex' }}>
                    <p className="paragraph-medium">
                        {status.trackingNumber}
                    </p>
                    <Tooltip title={<Box sx={{ padding: "0.18vw 0.6vw" }}> {i18n.getText('copyLinkToTracking')}</Box>}>
                        <IconButton
                            sx={{ padding: "0.18vw", ml: "0.5vw" }}
                            color="primary"
                            onClick={() => { navigator.clipboard.writeText(`${window.location.origin}/?trackNumber=${status.trackingNumber}`) }}
                        >
                            <LinkIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </div>

            <div className="tracker-field-divider"></div>
            {/* TODO: Get Estimated delivery value from API <div className="tracker-field_shiping-date">
                <p className="paragraph-small is--color-transparent-green">Estimated delivery</p>
                <p className="paragraph-medium">The delivery date will be provided as soon as posible.</p>
            </div> */}
            <div className="tracker-field-etaps">
                {steps?.Steps &&
                    <StatusStepper steps={steps.Steps} />
                }

                <ErrorMessage value={steps?.ExternalTrackingLoadingError && i18n.getText('ExternalTrackingLoadingError')} />
            </div>
            <div className="tracker-field_shiping-adres">
                <p className="paragraph-small is--color-transparent-green">{i18n.getText('ShipTo')}:</p>
                <p className="paragraph-medium">{GetShipTo()}</p>
            </div>
            <div className="tracker-field_shiping-buttons">
                <Link to="/" className="button w-button">{i18n.getText('TrackAnotherShipment')}</Link>
                {steps?.HasExtraHistory &&
                    <DetailsModal details={steps} externalLink={status.externalLink} /> ||
                    <ExternalLinkNotice externalLink={status.externalLink} />
                }
            </div>
        </div>
    );

    function GetShipTo(): string {
        return [status.shipToCity, status.shipToPostalCode, status.shipToCountry]
            .filter(x => x)
            .join(", ");
    }
}
