//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ParcelEntityType {
	UpsShipment = 0,
	ReturnLabel = 1,
	ConsolidationPackageGroup = 2,
	ParcelsWithoutAddress = 3,
	ParcelsAtReturnsWarehouse = 4,
	ParcelConsolidation = 5,
	NovaPoshtaParcels = 6,
	Unknown = -1
}
