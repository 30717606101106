import textsEn from './texts_en.json';
import textsRu from './texts_ru.json';
import textsUk from './texts_uk.json';

import moment from 'moment';

export function insertParams(text, parameters) {
  if (!parameters) throw new TypeError('Argument parameters is required and must be an object');

  // insert parameters
  let nextText = text;
  for (let key in parameters) {
    if (parameters.hasOwnProperty(key)) {
      nextText = nextText.replace('{{' + key + '}}', parameters[key]);
    }
  }

  return nextText;
}

export function insertArrayParams(text, parameters) {
  if (!parameters) throw new TypeError('Argument parameters is required and must be an object');

  // insert parameters
  let nextText = text;

  parameters.forEach((value, index) => {
    nextText = nextText.replace('{{' + index + '}}', value);
  });

  return nextText;
}

function I18nManager() {
  var texts;
  var selectedLanguage;

  switch (localStorage.getItem('language')) {
    case "UK":
      texts = textsUk;
      selectedLanguage = "UK";
      break;
    case "RU":
      texts = textsRu;
      selectedLanguage = "RU";
      break; 
    default:
      texts = textsEn;
      selectedLanguage = "EN";
      break;
  }

  return {
    getText: (code, parameters) => {
      let text = texts[code];
      if (!text) {
        text = textsEn[code];
      }

      if (!text) return code;

      if (!parameters) return text;

      if (Array.isArray(parameters)){
        return insertArrayParams(text, parameters);
      }
      return insertParams(text, parameters);
    },
    formatDate: (value) => {
      if (!value){
        return '';
      }

      let mDate = moment(value);
      if(mDate.isValid()){
        // Format L - default date format for selected locale. Locale's applying in App.js
        return mDate.format("L");
      }

      return value;
    },
    formatDateTime: (value) => {
      if (!value){
        return '';
      }

      let mDate = moment(value);
      if(mDate.isValid()){
        // Format LT - default time format for selected locale. Locale's applying in App.js
        return mDate.format("L LT");
      }

      return value;
    },
    formatNumber: (value) => {
      if (!value && value !== 0){
        return '';
      }

      return new Intl.NumberFormat(selectedLanguage, {useGrouping: false}).format(value)
    },
    getDate: (value) => {
      let mDate = moment(value);
      if (mDate.isValid()) {
        // trim the local time zone offset
        return new Date(mDate.format("YYYY-MM-DD")).toISOString()
      }
      return value;
    },
    getSelectedLanguage: () => {
      return selectedLanguage;
    },
  };
}

const i18n = new I18nManager();
export default i18n;
