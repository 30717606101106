//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CarrierType {
	Ups = 0,
	Dhl = 1,
	FedEx = 2,
	NovaPoshtaGlobal = 3,
	WestPost = 4,
	Dpd = 5,
	SkyPost = 6,
	UkrPost = 7,
	Undefined = 8,
	Usps = 9
}
