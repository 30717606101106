//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ShipmentType {
	UpsDirect = 0,
	UpsConsolidation = 1,
	UpsConsolidationPlus = 2,
	UkrPost = 3,
	UpsConsolidationForWarehouse = 4,
	Undefined = 5,
	UkrPostGroup = 6,
	UpsMultipackage = 7,
	VipParcelReturnLabel = 8,
	NewPost = 9,
	Justin = 10,
	Other = 11,
	ParcelsAtReturnsWarehouseLabel = 12,
	ParcelWithoutAddress = 13,
	FedExDirect = 14,
	Optimum = 15,
	NovaPoshtaGlobal = 16,
	SkyPost = 17,
	FulfillmentCenterWarehouse = 18,
	FedExDirectRU = 21,
	ConsolidationDpd = 22
}
