import { Urls } from '../constants/urls.constants';

export default function LogoImages({ isDarkBackground = false }) {
  const westernBidImage = isDarkBackground ? "images/Group-19977.svg" : "images/Group-19664.svg";
  const westParcelImage = isDarkBackground ? "images/Westparcel---SVG-file-01-1.svg" : "images/Group.svg";

  return (
    <>
      <a href={Urls.WesternBid}>
        <img src={westernBidImage} loading="lazy" alt="" className="tracker-logo-image is--1" />
      </a>
      <a href={Urls.WestParcel}>
        <img src={westParcelImage} loading="lazy" alt="" className="tracker-logo-image is--2" />
      </a>
    </>
  );
}