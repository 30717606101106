import * as React from 'react';

import LogoImages from './LogoImages';

import { Urls } from '../constants/urls.constants';
import { systemConstants } from '../constants/system.constants';
import i18n from '../i18n/I18nManager';

export default function FooterSection() {
  return (
    <footer className="alternative-footer-section">
      <div className="w-layout-blockcontainer container-default w-container">
        <div className="content-block">
          <div className="alternative-footer-wrapper">
            <div className="alternative-footer-left">
              <LogoImages />
            </div>
            <div className="alternative-footer-middle">
              <a href={Urls.WesternBidUserAgreementFile} className="w-inline-block">
                <div className="footer-text is--color-green">{i18n.getText('WesternBidUserAgreement')}</div>
              </a>
              <a href={Urls.WesternBidPrivacyPolicyFile} className="w-inline-block">
                <div className="footer-text is--color-green">{i18n.getText('WesternBidPrivacyPolicy')}</div>
              </a>
              <a href={Urls.WesternBidShippingAndHandlingPolicyFile} className="w-inline-block">
                <div className="footer-text is--color-green" dangerouslySetInnerHTML={{__html: i18n.getText('WesternBidShippingandHandlingPolicy')}}></div>
              </a>
            </div>
            <div id="w-node-ba41fc6b-21d3-215d-31c3-5cd219e1df7c-210695fd" className="alternative-footer-right">
              <div className="alternative-contact-wrapper">
                <img src="images/ic_outline-email.svg" loading="lazy" alt="" className="alternative-contact-image" />
                <a href={`mailto:${systemConstants.infoEmail}`}>
                  <div className="footer-text is--color-green">{systemConstants.infoEmail}</div>
                </a>
              </div>
              <div className="alternative-contact-wrapper">
                <img src="images/fluent_location-12-regular.svg" loading="lazy" alt="" className="alternative-contact-image" />
                <div className="footer-text is--color-green" dangerouslySetInnerHTML={{__html: i18n.getText('WesternBidContact')}}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}