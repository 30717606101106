import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

function LoadableContent({ isInitialized = true, isLoading, render, renderAsInitialized }:
  { isInitialized?: boolean, isLoading: boolean, render?: () => JSX.Element, renderAsInitialized?: boolean }) {
  const smartLoading = useSmartLoading(isLoading);
  return (
    <>
      {smartLoading &&
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress />
        </Backdrop>
      }

      {((isInitialized && renderAsInitialized) || !renderAsInitialized) && render &&
        render()}
    </>
  );
}

export default React.memo(LoadableContent);

// smart algorithm allows to skip loading blinking for the fast internet connection
export function useSmartLoading(isLoading: boolean) {
  const [smartLoading, setSmartLoading] = React.useState(false);
  const LOADING_DELAY = 200;
  const MIN_LOADING_TIME = 200;

  React.useEffect(() => {
    if (isLoading) {
      const loadingStartTime = Date.now() + LOADING_DELAY;
      const setSmartLoadingTimeout = setTimeout(() => {
        setSmartLoading(true);
      }, LOADING_DELAY);

      return () => {
        clearTimeout(setSmartLoadingTimeout);

        const loadingTime = Date.now() - loadingStartTime;
        setTimeout(() => {
          setSmartLoading(false);
        }, MIN_LOADING_TIME > loadingTime ? MIN_LOADING_TIME - loadingTime : 0);
      };
    }

    return () => { };
  }, [isLoading]);


  return smartLoading;
}
