import LogoImages from './LogoImages';

export default function NavBar() {
  return (
    <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav" style={{backgroundColor: "#f5f8f8"}}>
      <div className="nav-container w-container">
        <div className="nav-block w-nav-brand" style={{ paddingLeft: "7.7vw", maxHeight: "2.73611vw" }}>
          <LogoImages />
        </div>
      </div>
    </div>
  );
}