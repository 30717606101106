import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';

import i18n from '../../../i18n/I18nManager';

export default function ExternalLinkNotice({ externalLink }: { externalLink: string }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <a href={externalLink} onClick={handleClickOpen} className="tracker-field_shiping-link">{i18n.getText('ShipmentStatus.ViewDetails')}</a>
      <Dialog
        PaperProps={{
          style: {
            zIndex: 2,
            width: "55.5556vw",
            borderRadius: "1.73611vw",
            padding: "3.472vw",
            position: "relative",
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <Box className="popup-cross"
          aria-label="close"
          onClick={handleClose}
          sx={{
            backgroundColor: "#284E51",
            '@media (max-width: 479px)': {
              top: "2%",
            }
          }} >
          <img src="images/x-circle.svg" loading="lazy" alt="" className="popup-cross-image" />
        </Box>
        <div className="w-form">
          <div className="popup-heading">
            <h4>{i18n.getText('ExternalLinkNotice.Title')}</h4>
          </div>
          <div className="popup-subtitle">
            <p className="paragraph-medium">{i18n.getText('ExternalLinkNotice')}</p>
          </div>
          <div className="popup-down">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
              }}
            >
              <a href={externalLink} className="button w-button">{i18n.getText('ExternalLinkNotice.Accept')}</a>
            </Box>
          </div>
        </div>
      </Dialog>
    </>
  );
}