import i18n from '../../../i18n/I18nManager';

export default function ParcelField() {
    return (
        <div className="parcel-field">
            <div className="parcel-field_image-wrapper"><img src="images/64d0b24b2907f799c972e458_Rectangle2073.webp" loading="lazy" alt="" className="parcel-field-image" />
                <div className="parcel-field_image-gradient"></div>
            </div>
            <div className="parcel-field_image-heading">
                <h4 className="is--white">{i18n.getText("IntroducingWestParcel")}</h4>
            </div>
            <div className="parcel-field_image-button">
                <a href="./#IntroducingWestParcel" className="button is--white w-button" onClick={() => window.location.reload()}>{i18n.getText("IntroducingWestParcel.LearnMore")}</a>
            </div>
        </div>
    );
}