import { Urls } from '../../../constants/urls.constants';
import i18n from '../../../i18n/I18nManager';

export default function WestParcelSection() {
  return (
    <section className="parcel-section">
      <a id="IntroducingWestParcel" ></a>
      <div className="w-layout-blockcontainer container-default w-container">
        <div className="content-block">
          <div className="parcel-grid">
            <img src="images/64d0b24b2907f799c972e458_Rectangle2073.webp" loading="lazy" id="w-node-_2b07a348-79e6-9657-86c9-cf21ecdc76ee-210695fd" alt="" className="parcel-image" />
            <div id="w-node-_26f69e63-2301-4d56-3d7f-a44939fac52c-210695fd" className="parcel-text-wrapper">
              <div className="parcel-h1-wrapper">
                <h1>{i18n.getText('WestParcelSection.Title')}</h1>
              </div>
              <div className="parcel-subtitle-wrapper">
                <p className="paragraph-medium is--medium">{i18n.getText('WestParcelSection.Subtitle')}</p>
              </div>
              <div className="parcel-paragraph-wrapper">
                <p dangerouslySetInnerHTML={{__html: i18n.getText('WestParcelSection.Paragraph')}}></p>
              </div>
            </div>
          </div>
          <div className="parcel-button-block">
            <div className="parcel-button_block-header">
              <p className="paragraph-medium is--medium">{i18n.getText('WestParcelSection.ButtonBlockHeader')}</p>
            </div>
            <div className="parcel-button_block-buttons">
              <a href={Urls.WestParcel} className="button has--margin_lr-66 w-button">{i18n.getText('Ukraine')}</a>
              <a href={Urls.WestParcelCZ} className="button has--margin_lr-66 w-button">{i18n.getText('Czech Republic')}</a>
              <a href={Urls.WestParcelPL} className="button has--margin_lr-66 w-button">{i18n.getText('Poland')}</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}