import NavBar from '../../../components/NavBar';
import ParcelField from '../components/ParcelField';

export default function TrackerFieldSection({ children }) {
  return (
    <>
      <NavBar />
      <section className="tracker-field-section">
        <div className="w-layout-blockcontainer container-default w-container">
          <div className="content-block">
            <div className="tracker-flex">
              {children}
              <ParcelField />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}