//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum TrackingStatuses {
	Unknown = 0,
	Created = 1,
	Delivered = 2,
	OnTheWay = 3,
	Failure = 4,
	Returned = 5
}
